<template>
    <section class="tabbed-page cms1500">
        <form @submit.prevent="handleFormSubmit">
            <div class="ins-claim">
                <h1 class="bottom-40">CMS-1500 Claim Form</h1>
                <!-- Claims Details Section -->
                <div class="section">
                    <div class="section-label">
                        <span class="inline-block">Claim Details</span>
                    </div>
                    <div class="flex colx3">
                        <Input
                            class=""
                            type="date"
                            placeholder="Submit Date"
                            v-model="claimData.submit_dayt"
                            label="Submission Date"
                            :disabled="isDisabled"
                        />
                        <Input
                            class=""
                            type="text"
                            placeholder="Claim Status"
                            v-model="claimData.claim_status"
                            label="Claim Status"
                            :disabled="isDisabled"
                        />
                        <Input
                            class=""
                            type="text"
                            placeholder="Claim Number"
                            v-model="claimData.api_claim_id"
                            label="Claim Number"
                            :disabled="isDisabled"
                        />
                    </div>
                </div>

                <!-- Patient Insured Section -->
                <div class="section">
                    <div class="section-label"><span class="inline-block">Patient/Insured</span></div>
                    <div class="autofill bottom-15">
                        <div class="flex colx3 autofill">
                            <Input
                                class=""
                                type="text"
                                id="PatientFirst"
                                v-model="formValues.PatientFirst"
                                label="2. Patient First Name"
                                :disabled="isDisabled"
                            />
                            <Input
                                class=""
                                type="text"
                                id="PatientLast"
                                v-model="formValues.PatientLast"
                                label="2. Patient Last Name"
                                :disabled="isDisabled"
                            />
                            <Input
                                class=""
                                type="text"
                                id="PatientDOB"
                                v-model="formValues.PatientDOB"
                                label="3. Patient Birthdate"
                                :disabled="isDisabled"
                            />
                        </div>
                        <div class="flex colx4 space-between">
                            <Input
                                class=""
                                type="text"
                                id="PatientAddr1"
                                v-model="formValues.PatientAddr1"
                                label="5. Patient Street Address"
                            />
                            <Input
                                class=""
                                type="text"
                                id="PatientAddr2"
                                v-model="formValues.PatientAddr2"
                                label="5. Patient Street Address 2"
                            />
                            <Input
                                class=""
                                type="text"
                                id="PatientCity"
                                v-model="formValues.PatientCity"
                                label="5. Patient City"
                            />
                            <Dropdown
                                name="PatientState"
                                id="PatientState"
                                label="5. Patient State"
                                :options="UsStateOptions"
                                v-model="formValues.PatientState"
                            />
                            <Input
                                class=""
                                type="text"
                                id="PatientZip"
                                v-model="formValues.PatientZip"
                                label="5. Patient Zip Code"
                            />
                        </div>
                        <div class="flex colx4">
                            <Dropdown
                                name="PatientSex"
                                id="PatientSex"
                                label="3. Patient Sex"
                                :options="PatientSexOptions"
                                v-model="formValues.PatientGender"
                            />
                            <Dropdown
                                name="PatientRelateCode"
                                id="PatientRelateCode"
                                label="6. Patient Relationship to Insured"
                                :options="PatientRelationshipOptions"
                                v-model="formValues.Insurances[0].PatientRelateCode"
                            />
                            <Dropdown
                                name="PatientMaritalStatus"
                                id="PatientMaritalStatus"
                                label="8. Patient Marital Status"
                                :options="PatientMaritalStatus"
                                v-model="formValues.PatientMS"
                            />
                            <Dropdown
                                name="PatientRace"
                                id="PatientRace"
                                label="8. Patient Race"
                                :options="PatientRaceLabels"
                                v-model="formValues.PatientRace"
                            />
                        </div>
                        <div class="flex colx2">
                            <Dropdown
                                name="PatientEmploymentStatus"
                                id="PatientEmploymentStatus"
                                label="8. Patient Employment Status"
                                :options="PatientEmploymentStatus"
                                v-model="formValues.EmploymentStatus"
                            />
                            <!-- <Input class="" type="text" id="ReservedNucc" label="9c. Reserved for NUCC use" />-->
                            <Input
                                class=""
                                type="text"
                                id="InsGrpNum"
                                v-model="formValues.Insurances[0].GroupNumber"
                                label="11. Insured's Policy Group or FECA Number"
                            />
                        </div>
                    </div>
                </div>
                
                
                <div class="section">
                    <div class="label bottom-15">
                        <span class="inline-block">
                            <strong>10. Is Patient's Condition Related To: </strong>
                        </span>
                    </div>
                    <div class="flex colx2">
                        <Dropdown
                            name="RelatedEmployment"
                            id="RelatedEmployment"
                            label="10a. Employment?"
                            :options="[
                                { text: 'Yes', value: 'Y' },
                                { text: 'No', value: 'N' }
                            ]"
                            v-model="formValues.RelatedEmployment"
                        />
                        <Dropdown
                            name="RelatedAutoAccident"
                            id="RelatedAutoAccident"
                            label="10b. Auto Accident?"
                            :options="[
                                { text: 'Yes', value: 'Y' },
                                { text: 'No', value: 'N' }
                            ]"
                            v-model="formValues.RelatedAuto"
                        />
                        <Dropdown
                            name="RelatedOtherAccident"
                            id="RelatedOtherAccident"
                            label="10c. Other Accident?"
                            :options="[
                                { text: 'Yes', value: 'Y' },
                                { text: 'No', value: 'N' }
                            ]"
                            v-model="formValues.RelatedOther"
                        />
                    </div>
                </div>
                <div class="autofill">
                    <div class="flex colx3">
                        <Input
                            class=""
                            type="text"
                            id="SubscriberFirst"
                            v-model="formValues.Insurances[0].SubscriberFirst"
                            label="4. Insured First Name"
                        />
                        <Input
                            class=""
                            type="text"
                            id="SubscriberMiddle"
                            v-model="formValues.Insurances[0].SubscriberMiddle"
                            label="4. Insured Middle Inital "
                        />
                        <Input
                            class=""
                            type="text"
                            id="SubscriberLast"
                            v-model="formValues.Insurances[0].SubscriberLast"
                            label="4. Insured Last Name"
                        />
                    </div>
                    <div class="flex colx4">
                        <Input
                            class=""
                            type="text"
                            id="SubscriberAddr1"
                            v-model="formValues.Insurances[0].SubscriberAddr1"
                            label="7. Insured Street Address Line 1"
                        />
                        <Input
                            class=""
                            type="text"
                            id="SubscriberAddr2"
                            v-model="formValues.Insurances[0].SubscriberAddr2"
                            label="7. Insured Street Address Line 2 "
                        />
                        <Input
                            class=""
                            type="text"
                            id="SubscriberCity"
                            v-model="formValues.Insurances[0].SubscriberCity"
                            label="7. Insured City"
                        />
                        <Dropdown
                            name="SubscriberState"
                            id="SubscriberState"
                            label="7. Insured State"
                            :options="UsStateOptions"
                            v-model="formValues.Insurances[0].SubscriberState"
                        />
                    </div>
                    <div class="flex colx2">
                        <Input
                            class=""
                            type="text"
                            id="SubscriberZip"
                            v-model="formValues.Insurances[0].SubscriberZip"
                            label="7. Insured Zip Code"
                        />
                        <Input
                            class=""
                            type="text"
                            id="SubscriberPhone"
                            v-model="formValues.Insurances[0].SubscriberPhone"
                            label="7. Insured Phone"
                        />
                        <Input
                            class=""
                            type="text"
                            id="MemberID"
                            v-model="formValues.Insurances[0].SubscriberID.MemberID"
                            label="1a. Insured's ID Number"
                        />
                        <Input
                            class=""
                            type="text"
                            id="GroupNumber"
                            v-model="formValues.Insurances[0].GroupNumber"
                            label="Group Number"
                        />
                        <Dropdown
                            name="ClaimIndCode"
                            id="ClaimIndCode"
                            label="Claim Ind Code (Medicare/Medicaid)"
                            :options="ClaimIndCodeOptions"
                            v-model="formValues.Insurances[0].ClaimIndCode"
                        />
                    </div>
                </div>
                <div class="autofill bottom-15">
                    <div class="flex colx5">
                        <Input
                            class=""
                            type="date"
                            id="SubscriberDOB"
                            v-model="formValues.Insurances[0].SubscriberDOB"
                            label="11a. Insured's DOB"
                        />
                        <Dropdown
                            name="SubscriberSex"
                            id="SubscriberSex"
                            label="11a. Insured's Sex"
                            :options="PatientSexOptions"
                            v-model="formValues.Insurances[0].SubscriberGender"
                        />
                        <Input
                            class=""
                            type="text"
                            id="InsurancePlanName"
                            v-model="formValues.Insurances[0].PayerName"
                            label="11c. Insurance Plan or Program Name"
                        />
                        <Dropdown
                            name="AssignBenefits"
                            id="AssignBenefits"
                            label="11d. Is there another health benefit plan?"
                            :options="[
                                { text: 'Yes', value: 'Y' },
                                { text: 'No', value: 'N' }
                            ]"
                            v-model="formValues.Insurances[0].AssignBenefits"
                        />
                        <Dropdown
                            name="SignatureOnFile"
                            id="SignatureOnFile"
                            label="12. Signature On File?"
                            :options="[
                                { text: 'Yes', value: 'Y' },
                                { text: 'No', value: 'N' }
                            ]"
                            v-model="formValues.SignatureOnFile"
                        />
                    </div>
                </div>
                <div class="autofill bottom-15">
                    <div class="flex colx4">
                        <Input
                            class=""
                            type="date"
                            id="OnsetDate"
                            v-model="formValues.ClaimDate.OnsetCurrent"
                            label="14. Start Date of Current Illness, Injury, Pregnancy"
                        />
                        <Input
                            class=""
                            type="date"
                            id="OtherDate"
                            v-model="formValues.ClaimDate.OnsetSimiliar"
                            label="15. Other Date"
                        />
                        <Input
                            class=""
                            type="date"
                            id="NoWorkDate"
                            v-model="formValues.ClaimDate.LastWorked"
                            label="16. Start Date Patient Unable to Work"
                        />
                        <Input
                            class=""
                            type="date"
                            id="YesWorkDate"
                            v-model="formValues.ClaimDate.ReturnWork"
                            label="16. End Date Patient Unable to Work"
                        />
                    </div>
                </div>
                <!-- Encounter Details -->
                <div class="section-label"><span class="inline-block">Encounter Details</span></div>
                <div class="autofill bottom-15">
                    <div class="flex colx4">
                        <Input
                            class=""
                            type="text"
                            id="ReferringFirst"
                            v-model="formValues.ReferringFirst"
                            label="17a. Referring Provider First Name"
                        />
                        <Input
                            class=""
                            type="text"
                            id="ReferringLast"
                            v-model="formValues.ReferringLast"
                            label="17a. Referring Provider Last Name"
                        />
                        <Input
                            class=""
                            type="text"
                            v-model="formValues.ReferringProvID.NPI"
                            label="17 Referring ProviderID " />
                        <Input
                            class=""
                            type="text"
                            id="AdditionalInformation"
                            v-model="formValues.Box19"
                            label="19. Additional Information"
                        />
                    </div>
                    <div class="flex colx4">
                        <Input
                            class=""
                            type="date"
                            id="AdmissionDate"
                            v-model="formValues.ClaimDate.Admission"
                            label="18. Hospitalization Start Date"
                        />
                        <Input
                            class=""
                            type="date"
                            id="OffsetDate"
                            v-model="formValues.ClaimDate.Discharge"
                            label="18. Hospitalization End Date"
                        />
                        <Dropdown
                            name="SignatureOnFile"
                            id="OutsideLab"
                            label="20. Outside of Lab?"
                            :options="[
                                { text: 'Yes', value: 'Y' },
                                { text: 'No', value: 'N' }
                            ]"
                            v-model="formValues.OutsideLab"
                        />
                        <Input
                            class=""
                            type="text"
                            id="OffsetDate"
                            v-model="formValues.LabCharges"
                            label="20. Charges"
                        />
                    </div>
                </div>

                <div class="autofill bottom-15">
                    <div class="flex colx4">
                        <Input
                            class=""
                            type="text"
                            id="DiagVersion"
                            v-model="formValues.DiagnosisVersion"
                            label="21. ICD Diagnosis Version"
                            :disabled="isDisabled"
                        />
                        <Dropdown
                            v-if="formValues.DiagnosisCodes10 && formValues.DiagnosisCodes10.Principal"
                            class=""
                            id="DiagPrinciple"
                            label="21. a"
                            name="DiagA"
                            :options="diagnosisCodes"
                            v-model="formValues.DiagnosisCodes10.Principal"
                        />
                        <Dropdown
                            v-if="formValues.DiagnosisCodes10 && formValues.DiagnosisCodes10.Other1"
                            class=""
                            id="DiagB"
                            label="21. b"
                            name="DiagB"
                            :options="diagnosisCodes"
                            v-model="formValues.DiagnosisCodes10.Other1"
                        />
                        <Dropdown
                            v-if="formValues.DiagnosisCodes10 && formValues.DiagnosisCodes10.Other2"
                            class=""
                            id="DiagC"
                            label="21. c"
                            name="DiagC"
                            :options="diagnosisCodes"
                            v-model="formValues.DiagnosisCodes10.Other2"
                        />
                    </div>
                    <div class="flex colx3">
                        <Dropdown
                            v-if="formValues.DiagnosisCodes10 && formValues.DiagnosisCodes10.Other3"
                            class=""
                            id="DiagD"
                            label="21. d"
                            name="DiagD"
                            :options="diagnosisCodes"
                            v-model="formValues.DiagnosisCodes10.Other3"
                        />
                        <Dropdown
                            v-if="formValues.DiagnosisCodes10 && formValues.DiagnosisCodes10.Other4"
                            class=""
                            id="DiagE"
                            label="21. e"
                            name="DiagE"
                            :options="diagnosisCodes"
                            v-model="formValues.DiagnosisCodes10.Other4"
                        />
                        <Dropdown
                            v-if="formValues.DiagnosisCodes10 && formValues.DiagnosisCodes10.Other5"
                            class=""
                            id="DiagF"
                            label="21. f"
                            name="DiagF"
                            :options="diagnosisCodes"
                            v-model="formValues.DiagnosisCodes10.Other5"
                        />
                        <Dropdown
                            v-if="formValues.DiagnosisCodes10 && formValues.DiagnosisCodes10.Other6"
                            class=""
                            id="DiagG"
                            label="21. g"
                            name="DiagG"
                            :options="diagnosisCodes"
                            v-model="formValues.DiagnosisCodes10.Other6"
                        />
                    </div>
                    <div class="flex colx3">
                        <Dropdown
                            v-if="formValues.DiagnosisCodes10 && formValues.DiagnosisCodes10.Other7"
                            class=""
                            id="DiagH"
                            label="21. h"
                            name="DiagH"
                            :options="diagnosisCodes"
                            v-model="formValues.DiagnosisCodes10.Other7"
                        />
                        <Dropdown
                            v-if="formValues.DiagnosisCodes10 && formValues.DiagnosisCodes10.Other8"
                            class=""
                            id="DiagI"
                            label="21. i"
                            name="DiagI"
                            :options="diagnosisCodes"
                            v-model="formValues.DiagnosisCodes10.Other8"
                        />
                        <Dropdown
                            v-if="formValues.DiagnosisCodes10 && formValues.DiagnosisCodes10.Other9"
                            class=""
                            id="DiagJ"
                            label="21. j"
                            name="DiagJ"
                            :options="diagnosisCodes"
                            v-model="formValues.DiagnosisCodes10.Other9"
                        />
                        <Dropdown
                            v-if="formValues.DiagnosisCodes10 && formValues.DiagnosisCodes10.Other10"
                            class=""
                            id="DiagK"
                            label="21. k"
                            name="DiagK"
                            :options="diagnosisCodes"
                            v-model="formValues.DiagnosisCodes10.Other10"
                        />
                    </div>
                </div>
                <div class="autofill bottom-15">
                    <div class="flex colx2">
                        <Input
                            class=""
                            type="text"
                            id="ResubmissionCode"
                            v-model="formValues.Insurances[0].PayerICN"
                            label="22. Resubmission Code"
                        />
                        <Input
                            class=""
                            type="text"
                            id="PriorAuthNum"
                            v-model="formValues.Insurances[0].PriorAuthNo"
                            label="23. Prior Authorization Number"
                        />
                    </div>
                </div>
                <div class="autofill bottom-15">
                    <div class="flex colx4 wrap" v-for="service in formValues.Services" :key="service.LineNo">
                        <Input
                            class=""
                            type="date"
                            id="ServiceStartDate"
                            v-model="service.FromDate"
                            label="24a. Start Date of Service"
                        />
                        <Input
                            class=""
                            type="date"
                            id="ServiceEndDate"
                            v-model="service.ThroughDate"
                            label="24a. End Date of Service"
                        />
                        <Dropdown
                            class=""
                            id="PosCode"
                            label="24b. POS (2 Digit)"
                            name="PosCode"
                            :options="PosTwoDigitCodes"
                            v-model="service.PlaceService"
                        />
                        <Dropdown
                            class=""
                            id="ServiceHcpc"
                            label="24d. CPT/HCPCS"
                            name="ServiceHcpc"
                            :options="serviceFees"
                            v-model="service.HCPC"
                        />
                        <Dropdown
                            class=""
                            id="Emg"
                            label="24c. EMG"
                            name="Emg"
                            :options="[
                                { text: 'Yes', value: 'Y' },
                                { text: 'No', value: 'N' }
                            ]"
                            v-model="service.Emergency"
                        />
                        <Dropdown
                            class=""
                            id="Mod1"
                            label="24d. Modifier 1"
                            name="Mod1"
                            :options="ModifiersCodeList"
                            v-model="service.Modifier1"
                        />
                        <Dropdown
                            class=""
                            id="Mod2"
                            label="24d. Modifier 2"
                            name="Mod2"
                            :options="ModifiersCodeList"
                            v-model="service.Modifier2"
                        />
                        <Dropdown
                            class=""
                            id="Mod3"
                            label="24d. Modifier 3"
                            name="Mod3"
                            :options="ModifiersCodeList"
                            v-model="service.Modifier3"
                        />
                        <Dropdown
                            class=""
                            id="Mod4"
                            label="24d. Modifier 4"
                            name="Mod4"
                            :options="ModifiersCodeList"
                            v-model="service.Modifier4"
                        />
                        <Input

                            class=""
                            type="text"
                            id="DiagnosisPointer"
                            v-model="service.DiagnosisPointer"
                            label="24e. Diagnosis Pointer"
                        />
                        <Input
                            class=""
                            type="text"
                            v-model="service.Charge"
                            label="24F. $ Charges"
                        />
                        <Input
                            class=""
                            type="text"
                            id="ServiceUnits"
                            v-model="service.Units"
                            label="24g. Units"
                        />
                        <Dropdown
                            class=""
                            id="Epsdt"
                            label="24h. EPSDT"
                            name="Epsdt"
                            :options="[
                                { text: 'Yes', value: 'Y' },
                                { text: 'No', value: 'N' }
                            ]"
                            v-model="service.EPSDT"
                        />
                        <Input
                            class=""
                            type="text"
                            id="RenderingProviderId"
                            v-model="formValues.RenderingProvID.NPI"
                            label="24j. Rendering Provider ID"
                        />
                    </div>
                </div>
                <div class="autofill bottom-15">
                    <div class="flex colx2">
                        <Input
                            class=""
                            type="text"
                            v-model="formValues.BillingProvID.EIN"
                            label="25. Fedederal TaxID Number"
                        />
<!--                        <Input-->
<!--                            class=""-->
<!--                            type="text"-->
<!--                            v-model="formValues.PatientID.SSN"-->
<!--                            label="26. Patient's Account No."-->
<!--                        />-->
                        <Dropdown
                            class=""
                            id="AcceptAssignment"
                            label="27. Accept Assignment?"
                            name="AcceptAssignment"
                            :options="[
                                { text: 'Yes (A)', value: 'A' },
                                { text: 'Clinical Lab Services Only (B)', value: 'B' },
                                { text: 'No (C)', value: 'C' }
                            ]"
                            v-model="formValues.AcceptAssignment"
                        />
                        <Input
                            class=""
                            type="text"
                            id="TotalCharges"
                            v-model="formValues.ClaimCharge"
                            label="28. Total Charge"
                        />
                        <Input
                            class=""
                            type="text"
                            id="AmountPaid"
                            v-model="formValues.PatientPaid"
                            label="29. Amount Paid"
                        />
                    </div>
                    <div class="flex colx2">
                        <Input
                            class=""
                            type="text"
                            id="RenderingLast"
                            v-model="formValues.RenderingLast"
                            label="31. Last Name of Rendering Provider"
                        />
                        <Input
                            class=""
                            type="text"
                            id="RenderingFirst"
                            v-model="formValues.RenderingFirst"
                            label="31. First Name of Rendering Provider"
                        />
                    </div>
                </div>
                <!-- Service Facility Details -->
                <div class="section-label"><span class="inline-block">Service Facility Details</span></div>
                <div class="autofill bottom-15">
                    <div class="flex colx4">
                        <Input
                            class=""
                            type="text"
                            id="BillingName"
                            v-model="formValues.BillingName"
                            label="32. Service Facility Name"
                        />
                        <Input
                            class=""
                            type="text"
                            id="BillingAddr1"
                            v-model="formValues.BillingAddr1"
                            label="32. Service Facility Street Address Line 1"
                        />
                        <Input
                            class=""
                            type="text"
                            id="BillingAddr2"
                            v-model="formValues.BillingAddr2"
                            label="32. Service Facility Street Address Line 2"
                        />
                        <Input
                            class=""
                            type="text"
                            id="BillingAddr2"
                            v-model="formValues.BillingCity"
                            label="32. Service Facility City"
                        />
                    </div>
                    <div class="flex colx3">
                        <Dropdown
                            name="BillingState"
                            id="BillingState"
                            label="32. Service Facility State"
                            :options="UsStateOptions"
                            v-model="formValues.BillingState"
                        />
                        <Input
                            class=""
                            type="text"
                            id="BillingAddr2"
                            v-model="formValues.BillingZip"
                            label="32. Service Facility Zip Code"
                        />
                        <Input
                            class=""
                            type="text"
                            id="BillingProvIdNpi"
                            v-model="formValues.BillingProvID.NPI"
                            label="32. Service Facility NPI"
                        />
                    </div>
                </div>
            </div>

            <div class="align-right">
            <button type="submit" class="do-not-print-me">Save</button>
            <button v-if="claimData.saved && claimData.claim_status=='new'" type="button" @click.prevent="handleSubmitClaim" class="do-not-print-me">
                Submit Claim to Clearinghouse API
            </button>
            <button v-else-if="claimData.saved" type="button" @click.prevent="handleSubmitClaim" class="do-not-print-me">
                RE-Submit Claim to Clearinghouse API
            </button>

            <button v-if="0 && claimData.claim_status=='error'" type="button" @click.prevent="delete2api" class="do-not-print-me">
                DELETE Claim AT API ONLY (Keep here)
            </button>

            <button v-if="claimData.claim_status!='new'" type="button" @click.prevent="handle_check_claim_errors" class="do-not-print-me">
                Check Claim Validation / Errors
            </button>
            </div>
        </form>
    </section>
</template>

<script>
    import CurrencyInput from '@/components/general/inputs/CurrencyInput';
    import { getAllUsStatesAbbreviation } from '@/util/getUsStates';
    import {
        ModifiersCodeList,
        PatientEmploymentStatus,
        PatientMaritalStatus,
        PatientRaceLabels,
        PatientRelationshipOptions,
        PatientSexOptions,
        PosTwoDigitCodes,
        ClaimIndCodeOptions
    } from '@/util/claimOptions';

    export default {
        name: 'Cms1500',
        components: { CurrencyInput },
        props: {
            claimData: {
                type: Object,
                required: true
            },
            initialFormValues: {
                type: Object,
                required: true
            },
            diagnosisCodes: {
                type: Array,
                required: true
            },
            serviceFees: {
                type: Array,
                required: true
            }
        },
        data() {

            if (this.initialFormValues['DiagnosisCodes10']['Other1']['Code']) {
              const newDiagShape = {};
              for (const diag in this.initialFormValues.DiagnosisCodes10) {
                if (this.initialFormValues.DiagnosisCodes10[diag]['Code']) {
                    newDiagShape[diag] = this.initialFormValues.DiagnosisCodes10[diag]['Code'];
                } else {
                    newDiagShape[diag] = this.initialFormValues.DiagnosisCodes10[diag];
                }
              }
              this.initialFormValues.DiagnosisCodes10 = newDiagShape;
            }

            return {
                formValues: {
                    ...this.initialFormValues
                },
                isDisabled: true,
                UsStateOptions: getAllUsStatesAbbreviation(),
                PatientSexOptions,
                PatientRelationshipOptions,
                PatientMaritalStatus,
                PatientRaceLabels,
                PatientEmploymentStatus,
                PosTwoDigitCodes,
                ModifiersCodeList,
                ClaimIndCodeOptions
            };
        },
        methods: {
            handleFormSubmit() {
                this.$emit('saveForm', this.formValues);
            },
            handleSubmitClaim() {
                this.$emit('submitClaim', this.formValues);
            },
            handle_check_claim_errors() {
                this.$emit('check_claim_errors', this.formValues);
            },
            delete2api() {
                this.$emit('delete2api', this.formValues);
            },
        },
        watch: {
            initialFormValues: {
                deep: true,
                handler(newVal) {
                    this.formValues = { ...newVal };
                }
            }
        }
    };
</script>
