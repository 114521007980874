<template>
    <!-- <div>
        <section class="tabbed-page alerts">
            <ChirpSettings v-if="!loading" :data_source="rows['alert']" :level="'company'" :level_id="level_id" />
        </section>
        <section class="tabbed-page forms">
            <ChirpSettings v-if="!loading" :data_source="rows['form']" :level="'company'" :level_id="level_id" />
        </section>
    </div> -->
    <div v-if="!loading">
        <!-- TAB INPUTS -->
        <input
            v-if="!loading && (record_data.claim_status=='new' || rec.TransType=='PROF_CLAIM')"
            id="tab-1" class="page-tab-input" name="page-tab-input" type="radio" @click="changeClaimType('cms')"
            :checked="rec.TransType=='PROF_CLAIM'"
        />
        <input
            v-if="!loading && (record_data.claim_status=='new' || rec.TransType=='INST_CLAIM')"
            id="tab-2"
            class="page-tab-input"
            name="page-tab-input"
            type="radio"
            @click="changeClaimType('ub')"
            :checked="rec.TransType=='INST_CLAIM'"
        />

        <!-- Errors Section -->
        <div class="error-block card-block" v-if="record_data.is_validation_error">
            <div
                class="flex space-between center pointer error-toggle"
                @click="toggleErrorHeaderOpen"
                :class="{ open: errorHeaderOpen }"
            >
                <h3 class="margin-0 dark-text bold">Validation Errors ({{ record_data.api_response_json.length }})</h3>
                <span class="material-icons-outlined text-button dark-text close">close</span>
            </div>

            <div class="error-list">
                <div class="" v-for="error in record_data.api_response_json" :key="error.id">
                    <div class="text-button">
                        <span>• {{ error.name }}</span>
<!--                        <a target="_claim" href="record_data.api_claim_link">• {{ error.name }}</a>-->
                    </div>
                </div>
            </div>
        </div>

        <!-- TABS CONTAINER -->
        <div class="page-tabs-container">
            <h1>Health Insurance Claim for
                <router-link v-if="record_data && record_data.invoice_id" :to="{name: 'Invoice', params: {invoice_id: record_data.invoice_id}}">
                    Invoice {{ record_data.invoice_id }}
                </router-link>
             </h1>
            <label v-if="record_data.claim_status=='new' || rec.TransType=='PROF_CLAIM'" class="page-tab" for="tab-1">CMS 1500</label>
            <label v-if="record_data.claim_status=='new' || rec.TransType=='INST_CLAIM'" class="page-tab" for="tab-2">UB-04</label>
        </div>

        <Cms1500
            v-if="!loading && (record_data.claim_status=='new' || rec.TransType=='PROF_CLAIM')"
            :claimData="record_data"
            :initialFormValues="cms1500_rec"
            :diagnosisCodes="diagnosis_codes"
            :serviceFees="service_fees"
            @saveForm="updateRecord"
            @submitClaim="submit_claim"
            @check_claim_errors="check_claim_errors"
            @delete2api="delete2api"
        />

        <Ub04
            v-if="!loading && (record_data.claim_status=='new' || rec.TransType=='INST_CLAIM')"
            :claimData="record_data"
            :initialFormValues="ub04_rec"
            :diagnosisCodes="diagnosis_codes"
            :serviceFees="service_fees"
            @saveForm="updateRecord"
            @submitClaim="submit_claim"
            @check_claim_errors="check_claim_errors"
            @delete2api="delete2api"
        />

        <!--  -->
    </div>
</template>
<script>
    import Accordion from '@/components/general/accordion/Accordion';
    import { getCurrency } from '@/util/getCurrency';
    import CurrencyInput from '@/components/general/inputs/CurrencyInput';
    import { getAllUsStatesAbbreviation } from '@/util/getUsStates';
    import {
        AdmissionSourceOptions,
        AdmissionTypeOptions,
        AssignBenefitsOptions,
        BillTypeOptions,
        ClaimTypeOptions,
        ConditionCodesOptions,
        MediaCodeOptions,
        ModifiersCodeList,
        PatientEmploymentStatus,
        PatientMaritalStatus,
        PatientRaceLabels,
        PatientRelationshipOptions,
        PatientSexOptions,
        PatientStatusOptions,
        PosTwoDigitCodes,
        ROIOptions,
        ValueCodeOptions
    } from '@/util/claimOptions';
    import Cms1500 from '@/components/insurance/Cms1500';
    import Ub04 from '@/components/insurance/Ub04';

    const api_root = 'insurance/claim';
    const service_code_root = 'service_fees';

    export default {
        name: 'InsuranceClaimDetail',
        components: { Ub04, Cms1500, Accordion, CurrencyInput },
        props: {
            record_id: [String, Number]
        },
        data() {
            return {
                loading:true,
                tempHolding: '',
                record_data: {
                    active: 0,
                    is_validation_error: false,
                    insurance_payer_id: 1,
                    claim_status: '',
                    npi: '',
                    insurance_era_id: 1,
                    submit_dayt: 'dayt',
                    claim_fee: 1,
                    client_id: '',
                    api_request_json: {},
                    api_response_json: {},
                    api_claim_id: 1,
                    api_claim_link: ''
                    // fields from hq
                },
                service_fees: [],
                diagnosis_codes: [],
                isEditable: true,
                record_actions: {
                    getRecordDetails: (id) => `/${api_root}/${id}`,
                    updateRecord: (id) => `/insurance/save-claim/${id}`
                },
                ub04ConditionCodesInitial : {
                    ConditionCodes: {
                        Code1: '',
                        Code2: '',
                        Code3: '',
                        Code4: '',
                        Code5: '',
                        Code6: '',
                        Code7: '',
                        Code8: '',
                        Code9: '',
                        Code10: '',
                        Code11: ''
                    }
                },
                ub04_rec : {},
                cms1500_rec : {},
                rec: {
                    OutsideLab: '',
                    LabCharges: '',
                    AcceptAssignment: '',
                    Attachments: [],
                    BillingAddr1: '',
                    BillingAddr2: '',
                    BillingCity: '',
                    BillingCountry: '',
                    BillingName: '',
                    BillingProvID: {
                        EIN: '',
                        NPI: ''
                    },
                    PayToProvID: {
                        NPI: ''
                    },
                    BillingState: '',
                    BillingZip: '',
                    ClaimCharge: '',
                    ClaimDate: {
                        Admission: null,
                        Discharge: null,
                        LastWorked: null,
                        OnsetCurrent: null,
                        OnsetSimilar: null,
                        ReturnWork: null
                    },
                    ClaimID: {
                        ClaimNo: ''
                    },
                    DestInsNo: 0,
                    DiagnosisCodes10: {
                        Principal: {
                            Code: '',
                            POA: ''
                        },
                        Other1: {
                            Code: ''
                        },
                        Other2: {
                            Code: ''
                        },
                        Other3: {
                            Code: ''
                        },
                        Other4: {
                            Code: ''
                        },
                        Other5: {
                            Code: ''
                        },
                        Other6: {
                            Code: ''
                        },
                        Other7: {
                            Code: ''
                        },
                        Other8: {
                            Code: ''
                        },
                        Other9: {
                            Code: ''
                        },
                        Other10: {
                            Code: ''
                        }
                    },
                    DiagnosisVersion: '',
                    EmploymentStatus: '',
                    ImportBatchID: '',
                    Insurances: [
                        {
                            AssignBenefits: '',
                            ClaimIndCode: '',
                            GroupName: '',
                            GroupNumber: '',
                            PatientRelateCode: '18',
                            PayerAddr1: '',
                            PayerAddr2: '',
                            PayerCity: '',
                            PayerCountry: '',
                            PayerID: {
                                ID: '',
                                Local: ''
                            },
                            PayerName: '',
                            PayerSeqCode: '',
                            PayerState: '',
                            PayerZip: '',
                            PriorAuthNo: '',
                            ReleaseInfoCode: '',
                            SubscriberAddr1: '',
                            SubscriberAddr2: '',
                            SubscriberCity: '',
                            SubscriberCountry: '',
                            SubscriberDOB: '',
                            SubscriberFirst: '',
                            SubscriberGender: '',
                            SubscriberPhone: '',
                            SubscriberID: {
                                MemberID: ''
                            },
                            SubscriberLast: '',
                            SubscriberMiddle: '',
                            SubscriberState: '',
                            SubscriberZip: ''
                        }
                    ],
                    MediaCode: '',
                    PatientAddr1: '',
                    PatientAddr2: '',
                    PatientCity: '',
                    PatientCtlNo: '',
                    PatientDOB: '',
                    PatientFirst: '',
                    PatientGender: '',
                    PatientID: {
                        SSN: ''
                    },
                    PatientLast: '',
                    PatientMS: '',
                    PatientMiddle: '',
                    PatientPaid: '',
                    PatientRace: '',
                    PatientState: '',
                    PatientZip: '',
                    ReferringFirst: '',
                    ReferringLast: '',
                    ReferringMiddle: '',
                    ReferringProvID: {
                        NPI: ''
                    },
                    RelatedAuto: '',
                    RelatedEmployment: '',
                    RelatedOther: '',
                    RenderingFirst: '',
                    RenderingLast: '',
                    RenderingProvID: {
                        NPI: ''
                    },
                    Services: [],
                    SignatureOnFile: '',
                    StatementEnd: '',
                    StatementStart: '',
                    Box19: '',
                    TransType: 'INST_CLAIM'
                },
                ClaimTypeOptions,
                PatientSexOptions,
                UsStateOptions: getAllUsStatesAbbreviation(),
                PatientRelationshipOptions,
                PatientMaritalStatus,
                PatientRaceLabels,
                PatientEmploymentStatus,
                PosTwoDigitCodes,
                ModifiersCodeList,
                BillTypeOptions,
                AdmissionTypeOptions,
                AdmissionSourceOptions,
                PatientStatusOptions,
                ConditionCodesOptions,
                ROIOptions,
                AssignBenefitsOptions,
                MediaCodeOptions,
                ValueCodeOptions,
                errorHeaderOpen: false
            };
        },
        methods: {
            async init() {
                if (!(this.record_id === 'new')) {
                    const res = await this.$api.get(this.record_actions.getRecordDetails(this.record_id));

                    if (res.status === 404 || res.status === 500) {
                        this.$toasted.error('Could not find record');
                        return this.$router.replace({ name: 'NotFound' });
                    } else {
                        this.record_data = res.data.info[0];
                        this.record_data.submit_dayt = this.record_data.submit_dayt.substring(
                            0,
                            this.record_data.submit_dayt.indexOf('T')
                        );
                        this.level_id = this.$store.state.user.company_id;
                        const serFeeRes = await this.$api.get(`/${service_code_root}/list/company/${this.level_id}`);
                        const dxCodes = await this.$api.get(`/services/dx-codes`);
                        this.diagnosis_codes = dxCodes.data.map((element) => {
                            return { text: `${element.service_code} - ${element.abbrev}`, value: element.service_code };
                        });

                        const rec = JSON.parse(this.record_data.api_request_json);

                        [
                            'Other1',
                            'Other2',
                            'Other3',
                            'Other4',
                            'Other5',
                            'Other6',
                            'Other7',
                            'Other8',
                            'Other9',
                            'Other10'
                        ].forEach((o) => {
                            if (!rec.DiagnosisCodes10[o]) {
                                rec.DiagnosisCodes10[o] = { Code: '' };
                            }
                        });

                        this.rec = rec;

                        this.service_fees = serFeeRes.data.map((element) => {
                            return { text: `${element.service_code} - ${element.abbrev}`, value: element.service_code };
                        });

                        if (!this.rec.ClaimDate) {
                            this.rec.ClaimDate = {};
                            this.rec.ClaimDate.OnsetSimiliar = this.rec.ClaimDate.OnsetSimiliar
                                ? this.rec.ClaimDate.OnsetSimiliar
                                : null;
                            this.rec.ClaimDate.ReturnWork = this.rec.ClaimDate.ReturnWork
                                ? this.rec.ClaimDate.ReturnWork
                                : null;
                            this.rec.ClaimDate.OnsetCurrent = this.rec.ClaimDate.OnsetCurrent
                                ? this.rec.ClaimDate.OnsetCurrent
                                : null;
                            this.rec.ClaimDate.LastWorked = this.rec.ClaimDate.LastWorked
                                ? this.rec.ClaimDate.LastWorked
                                : null;
                            this.rec.ClaimDate.Admission = this.rec.ClaimDate.Admission
                                ? this.rec.ClaimDate.Admission
                                : null;
                            this.rec.ClaimDate.Discharge = this.rec.ClaimDate.Discharge
                                ? this.rec.ClaimDate.Discharge
                                : null;
                        }

                        if (this.rec.ClaimCharge) {
                            this.record_data.claim_fee = getCurrency(this.rec.ClaimCharge);
                        }
                        if (this.rec.ClaimID && this.rec.ClaimID.ClaimNo) {
                            this.record_data.api_claim_id = this.rec.ClaimID.ClaimNo;
                            let claim_type = 'prof'
                            if (this.rec.TransType=='INST_CLAIM') {
                                claim_type = 'inst'
                            }

                            this.record_data.api_claim_link = `https://portal.focusedi.com/claim/view/${this.record_data.api_claim_id}/${claim_type}/0/`;
                        }
                        if (!this.rec.RenderingProvID) {
                            this.rec.RenderingProvID = {
                                NPI:''
                            }
                        }
                        if (!this.rec.RenderingProvID.NPI) {
                            this.rec.RenderingProvID.NPI = '';
                        }
                        if (this.rec.RenderingProvID.NPI) {
                            this.record_data.npi = this.rec.RenderingProvID.NPI;
                        }

                        if (!this.rec.ReferringProvID) {
                            this.rec.ReferringProvID = {
                                NPI:''
                            }
                        }
                        if (!this.rec.ReferringProvID.NPI) {
                            this.rec.ReferringProvID.NPI = '';
                        }

                        if (!this.rec.BillingProvID) {
                            this.rec.BillingProvID = {
                                NPI:''
                            }
                        }
                        if (!this.rec.BillingProvID.NPI) {
                            this.rec.BillingProvID.NPI = '';
                        }

                        if (!this.rec.PayToProvID) {
                            this.rec.PayToProvID = {
                                NPI:''
                            }
                        }
                        if (!this.rec.PayToProvID.NPI) {
                            this.rec.PayToProvID.NPI = '';
                        }

                        this.loading = false
                        if (this.record_data.claim_status === 'error') {
                            this.record_data.is_validation_error = true;
                            if (this.record_data.api_response_json) {
                                this.record_data.api_response_json = JSON.parse(this.record_data.api_response_json).reduce(
                                    (out, cur) => {
                                        if (!out.find((e) => e.id === cur.id)) {
                                            out.push(cur);
                                        }

                                        return out;
                                    },
                                    []
                                );
                            }
                        }
                    }
                }
                if ((this.record_id === 'new' || this.rec.TransType == 'INST_CLAIM') && !this.rec.ConditionCodes) {
                    this.rec.ConditionCodes = this.$clone_obj(this.ub04ConditionCodesInitial.ConditionCodes);
                }

                this.ub04_rec = this.$clone_obj(this.rec);
                this.$set(this.ub04_rec, 'TransType', 'INST_CLAIM');
                if (!this.ub04_rec.DiagnosisCodes10.Principal.Code) {
                    this.ub04_rec.DiagnosisCodes10.Principal = {
                        Code: this.ub04_rec.DiagnosisCodes10.Principal.toString(),
                        POA: ''
                    }
                }

                this.cms1500_rec = this.$clone_obj(this.rec);
                this.$set(this.cms1500_rec, 'TransType', 'PROF_CLAIM');

                // @todo need to separate more of these before submit + locked in, but for now, seems to work

                this.loading = false
            },
            async updateRecord(formValues) {
                try {
                    if (formValues.TransType) {
                        if (formValues.TransType == 'PROF_CLAIM') {
                            this.record_data.form_type = 'CMS-1500';
                        }
                        if (formValues.TransType == 'INST_CLAIM') {
                            this.record_data.form_type = 'UB-04';
                        }
                    }
                    // if (this.record_data.api_claim_link) {
                    //     delete this.record_data.api_claim_link;
                    // }
                    // if (this.record_data.is_validation_error) {
                    //     delete this.record_data.is_validation_error;
                    // }

                    // for (const key in formValues.DiagnosisCodes10) {
                    //     if (this.rec.DiagnosisCodes10[key].Code === '') {
                    //         delete this.rec.DiagnosisCodes10[key];
                    //     }
                    // }

                    this.record_data.api_request_json = formValues;
                    this.loading = true
                    const res = await this.$api.post(this.record_actions.updateRecord(this.record_id), formValues);
                    this.$toasted.success(`Successfully updated Claim Record ID ${res.data.recId}.`); // TODO: A bit more logic needed to verify this was successful, yeah?
                    await this.init();
                } catch (err) {
                    this.$toasted.error(
                        `Failed to update Claim Record ${this.record_data.api_claim_id}. Please try again later.`
                    );
                }
            },
            changeClaimType(type) {
                if (type == 'cms') {
                    this.$set(this.rec, 'TransType', 'PROF_CLAIM');
                } else if (type == 'ub') {
                    this.$set(this.rec, 'TransType', 'INST_CLAIM');
                }
                this.$forceUpdate(); // :O
            },
            async submit_claim() {
                try {
                    const res = await this.$api.get(`/insurance/submit2api/${this.record_id}`);
                    this.$toasted.success(`Submit Claim Response : ${res.data.info}`); // TODO: Add some logic to verify this was successful...
                    // we need an empty claim date object in case it was deleted when we submit to api
                    if (!this.rec.ClaimDate) {
                        this.rec.ClaimDate = {};
                    }

                    await this.init(); // Doing this because the API from ability could have returned errors that we can't see without a refresh?
                } catch (err) {
                    this.$toasted.error(`Submit Claim Error : ${err.message}`);
                }
            },
            async delete2api() {
                try {
                    const res = await this.$api.delete(`/insurance/delete2api/${this.record_id}`)
                    this.$toasted.success(`DELETE Claim Response : ${res.data.info}`)
                } catch (err) {
                    this.$toasted.error(`DELETE Claim Error : ${err.message}`);
                }
            },
            async check_claim_errors() {
                try {
                    const res = await this.$api.get(`/insurance/check_claim_errors/${this.record_id}`);
                    this.$toasted.success(`Submit Claim Response : ${res.data.info}`); // TODO: Add some logic to verify this was successful...
                    // we need an empty claim date object in case it was deleted when we submit to api
                    if (!this.rec.ClaimDate) {
                        this.rec.ClaimDate = {};
                    }

                    await this.init(); // Doing this because the API from ability could have returned errors that we can't see without a refresh?
                } catch (err) {
                    this.$toasted.error(`Submit Claim Error : ${err.message}`);
                }
            },
            async validateForm() {
                const isValid = 1; // await this.$refs.form.validate()

                if (!isValid) {
                    this.$toasted.error('Please fill out all required fields.');
                }

                return isValid;
            },
            toggleErrorHeaderOpen() {
                this.errorHeaderOpen = !this.errorHeaderOpen;
            },
            handleCurrencyChanged(newCurrencyVal, service, i) {
                service.actual_cost = this.$getNumFromCurrency(newCurrencyVal);
                this.makeIndexDirty(i);
            }
        },
        async created() {
            this.$set(this.rec, 'TransType', 'INST_CLAIM');
            await this.init();
        },
        watch: {
            async $route() {
                await this.init();
            }
        }
    };
</script>
