<template>
    <section class="tabbed-page ub04">
        <form @submit.prevent="handleFormSubmit">
            <div class="ins-claim">
                <h1 class="bottom-60">UB-04 Claim Form</h1>
                <!-- Claims Details Section -->
                <div class="section">
                    <div class="section-label">
                        <span class="inline-block">Claim Details</span>
                    </div>
                    <div class="flex wrap colx3">
                        <Input
                            class=""
                            type="date"
                            placeholder="Submit Date"
                            v-model="claimData.submit_dayt"
                            label="Submission Date"
                            :disabled="true"
                        />
                        <Input
                            class=""
                            type="text"
                            placeholder="Claim Status"
                            v-model="claimData.claim_status"
                            label="Claim Status"
                            :disabled="true"
                        />
                        <Input
                            class=""
                            type="text"
                            placeholder="Claim Number"
                            v-model="claimData.api_claim_id"
                            label="Claim Number"
                            :disabled="true"
                        />
                    </div>
                </div>
                <!-- Billing Provider Section -->
                <div class="section">
                    <div class="section-label"><span class="inline-block">Billing Provider</span></div>
                    <div class="flex wrap colx4">
                        <Input type="text" v-model="formValues.ProgramName" label="Program Name" />
                        <Input type="text" v-model="formValues.BillingName" label="1. Provider Name " />
                        <Input type="text" v-model="formValues.BillingAddr1" label="1. Provider Street Address Line 1" />
                        <Input type="text" v-model="formValues.BillingAddr2" label="1. Provider Street Address Line 2" />
                        <Input type="text" v-model="formValues.BillingCity" label="1. Provider City" />
                        <Dropdown label="1. Provider State" :options="UsStateOptions" v-model="formValues.BillingState" />
                        <Input type="text" v-model="formValues.BillingZip" label="1. Provider Zip Code" />
                        <Input type="text" v-model="formValues.BillingProvID.EIN" label="5. Federal Tax Number" />
                        <Input type="text" v-model="formValues.BillingProvID.NPI" label="56 Company NPI " />
                        <Input type="text" v-model="formValues.OtherProviderIDs" label="57 Other Provider IDs " />
                    </div>
                </div>

                <!-- Pay To Provider Section -->
                <div class="section">
                    <div class="section-label"><span class="inline-block">Pay To Provider</span></div>
                    <div class="flex wrap colx4">
                        <Input type="text" v-model="formValues.PayToName" label="2. Pay To Provider Name" />
                        <Input type="text" v-model="formValues.PayToAddr1" label="2. Pay To Provider Address Line 1" />
                        <Input type="text" v-model="formValues.PayToAddr2" label="2. Pay To Provider Address Line 2" />
                        <Input type="text" v-model="formValues.PayToCity" label="2. Pay To Provider  City" />
                        <Dropdown
                            label="2. Pay To Provider State"
                            :options="UsStateOptions"
                            v-model="formValues.PayToState"
                        />
                        <Input type="text" v-model="formValues.PayToZip" label="2. Pay To Provider Zip Code" />
                        <Input type="text" v-model="formValues.PayToProvID.NPI" label="2. Pay to Provider NPI" />
                    </div>
                </div>

                <!-- Patient/Insured Section -->
                <div class="section">
                    <div class="section-label"><span class="inline-block">Patient/Insured</span></div>
                    <div class="flex wrap colx4">
                    <!-- <Input type="text" v-model="formValues.PatientID.SSN" label="8a. Patient ID SSN" />-->
                        <Input type="text" v-model="formValues.PatientLast" label="8b. Patient Last Name" />
                        <Input type="text" v-model="formValues.PatientFirst" label="8b. Patient First Name" />
                        <Input type="text" v-model="formValues.PatientAddr1" label="9a. Patient Street Address Line 1 " />
                        <Input type="text" v-model="formValues.PatientAddr2" label="9a. Patient Street Address Line 2" />
                        <Input type="text" v-model="formValues.PatientCity" label="9b. Patient City" />
                        <Dropdown label="9b. Patient State" :options="UsStateOptions" v-model="formValues.PatientState" />
                        <Input type="text" v-model="formValues.PatientZip" label="9b. Patient Zip Code" />
                        <Input type="text" v-model="formValues.PatientDOB" label="10. Patient Birthdate" />
                        <Dropdown
                            label="11. Patient Sex "
                            :options="PatientSexOptions"
                            v-model="formValues.PatientGender"
                        />
                    </div>
                    <div class="flex wrap colx4">
                        <Input type="text" v-model="formValues.Insurances[0].SubscriberLast" label="58 Insured’s Last Name " />
                        <Input type="text" v-model="formValues.Insurances[0].SubscriberFirst" label="58 Insured’s First Name " />
                        <Dropdown
                            label="59 Patient’s Relation to the Insured "
                            :options="PatientRelationshipOptions"
                            v-model="formValues.Insurances[0].PatientRelateCode"
                        />
                        <Input type="text" v-model="formValues.Insurances[0].SubscriberID.MemberID" label="60 Insured’s Unique ID" />
                        <Input type="text" v-model="formValues.Insurances[0].GroupName" label="61 Insured Group Name " />
                        <Input type="text" v-model="formValues.Insurances[0].GroupNumber" label="62 Insured Group Number " />
                        <Input type="text" v-model="formValues.PriorAuthNo" label="63 Treatment Authorization Codes " />
                        <Input type="text" v-model="formValues.PayerICN" label="64 Document Control Number " />
                        <Input type="text" v-model="formValues.EmployerName" label="65 Employer Name " />
                    </div>
                </div>

                <!-- Encounter Details Section -->
                <div class="section">
                    <div class="section-label"><span class="inline-block">Encounter Details</span></div>
                    <div class="flex wrap colx4">
                        <Input type="date" v-model="formValues.AdmissionDate" label="12. Admission Date" />
                        <Input type="text" v-model="formValues.AdmissionHour" label="13. Admission Hour " />
                        <Dropdown
                            label="14. Type of Admission/Visit "
                            :options="AdmissionTypeOptions"
                            v-model="formValues.AdmissionType"
                        />
                        <Dropdown
                            label="15. Source of Admission "
                            :options="AdmissionSourceOptions"
                            v-model="formValues.AdmissionSource"
                        />
                        <Input type="text" v-model="formValues.DischargeHour" label="16 Discharge Hour Required " />
                        <Dropdown
                            label="17 Patient Discharge Status "
                            :options="PatientStatusOptions"
                            v-model="formValues.PatientStatus"
                        />
                        <Input type="text" v-model="formValues.Description" label="43 Description" />
                    </div>
                    <div class="" v-for="service in formValues.Services" :key="service.LineNo">
                        <div class="flex wrap colx4">
                            <Input
                                class=""
                                type="text"
                                id="Revenue CodeUb04"
                                v-model="service.RevenueCode"
                                label="42 Revenue Code"
                            />
                            <Input
                                class=""
                                type="date"
                                id="ServiceStartDateUb04"
                                v-model="service.FromDate"
                                label="45 Service Date "
                            />
                            <Input
                                class=""
                                type="date"
                                id="ServiceEndDateUb04"
                                v-model="service.ThroughDate"
                                label="24a. End Date of Service"
                            />
                        </div>
                        <div class="flex">
                            <Dropdown
                                class=""
                                id="ServiceHcpcUb04"
                                label="44 HCPC"
                                name="ServiceHcpc"
                                :options="serviceFees"
                                v-model="service.HCPC"
                            />
                            <Dropdown
                                class=""
                                id="Mod1Ub04"
                                label="Modifier 1"
                                name="Mod1"
                                :options="ModifiersCodeList"
                                v-model="service.Modifier1"
                            />
                            <Dropdown
                                class=""
                                id="Mod2Ub04"
                                label="Modifier 2"
                                name="Mod2"
                                :options="ModifiersCodeList"
                                v-model="service.Modifier2"
                            />
                            <Dropdown
                                class=""
                                id="Mod3Ub04"
                                label="Modifier 3"
                                name="Mod3"
                                :options="ModifiersCodeList"
                                v-model="service.Modifier3"
                            />
                            <Dropdown
                                class=""
                                id="Mod4Ub04"
                                label="Modifier 4"
                                name="Mod4"
                                :options="ModifiersCodeList"
                                v-model="service.Modifier4"
                            />
                        </div>
                        <div class="flex">
                            <Input
                                class=""
                                type="text"
                                v-model="service.Charge"
                                label="47 Service Charge"
                            />
                            <Input
                                class=""
                                type="text"
                                id="ServiceUnitsUb04"
                                v-model="service.Rate"
                                label="44 Rate"
                            />
                            <Input
                                class=""
                                type="text"
                                id="ServiceUnitsUb04"
                                v-model="service.Units"
                                label="46 Units of Service"
                            />
                            <Input type="text" v-model="service.RenderingLast" label="Rendering Provider Last Name" />
                            <Input type="text" v-model="service.RenderingFirst" label="Rendering Provider First Name" />
                            <Input type="text" v-model="service.RenderingProvID.NPI" label="Rendering Provider NPI " />
                        </div>
                    </div>
                </div>

                <!-- Condition Codes Section -->
                <div class="section">
                    <div class="section-label"><span class="inline-block">18-28 Condition Codes </span></div>
                    <div class="flex wrap colx4">
                        <span
                            v-for="(v, k, i) in formValues.ConditionCodes"
                            :key="k"
                        >
                            <Dropdown
                                :label='(i+18) + ". Condition Code " + (i+1)'
                                :options="ConditionCodesOptions"
                                v-model="formValues.ConditionCodes[k]"
                            />
                        </span>
                    </div>
                </div>

                <!-- Occurrence Details Section -->
                <div class="section">
                    <div class="section-label"><span class="inline-block">Occurrence Details</span></div>
                    <div class="flex wrap colx4">
                        <Dropdown label="29 Accident State " :options="UsStateOptions" v-model="formValues.AutoAccState" />
                        <Input type="text" v-model="formValues.OccurenceCode1" label="31. Occurence 1 Code" />
                        <Input type="date" v-model="formValues.OccurenceCode1Date" label="31. Occurence 1 Date" />
                        <Input type="text" v-model="formValues.OccurenceCode2" label="32. Occurence 2 Code" />
                        <Input type="date" v-model="formValues.OccurenceCode2Date" label="32. Occurence 2 Date" />
                        <Input type="text" v-model="formValues.OccurenceCode3" label="33. Occurence 3 Code" />
                        <Input type="date" v-model="formValues.OccurenceCode3Date" label="33. Occurence 3 Date" />
                        <Input type="text" v-model="formValues.OccurenceCode4" label="34. Occurence 4 Code" />
                        <Input type="date" v-model="formValues.OccurenceCode4Date" label="34. Occurence 4 Date" />
                    </div>
                </div>

                <!-- Value Codes Section  add later when time permits -->
                <!--                    <div class="section-label"><span class="inline-block">Value Codes</span></div>-->
                <!--                    <div class="flex wrap colx4">-->
                <!--                        <Dropdown label="39a. Value Code" :options="ValueCodeOptions" v-model="" />-->
                <!--                    </div>-->

                <!-- Claim Details Section -->
                <div class="section">
                    <div class="section-label"><span class="inline-block">Claim Details</span></div>
                    <div class="flex wrap colx4">
                        <Input type="date" v-model="formValues.StatementStart" label="6. Statement Start Date" />
                        <Input type="date" v-model="formValues.StatementEnd" label="6. Statement End Date" />
                        <Dropdown label="4. Type of Bill" :options="BillTypeOptions" v-model="formValues.BillType" />
                        <Input type="text" v-model="formValues.ClaimCharge" label="Total Claim Charges" />
                        <Input type="text" v-model="formValues.NonCovered" label="48 Non-Covered Charges " />
                    </div>
                    <div class="flex wrap colx4">
                        <Input type="text" v-model="formValues.Insurances[0].PayerID.ID" label="50/51 Payer ID" />
                        <Input type="text" v-model="formValues.Insurances[0].PayerName" label="50/51 Payer Name" />
                        <Dropdown
                            label="52 Release of Info Certification "
                            :options="ROIOptions"
                            v-model="formValues.ReleaseInfoCode"
                        />
                        <Dropdown
                            label="53 Assignment of Benefit Certification"
                            :options="AssignBenefitsOptions"
                            v-model="formValues.AssignBenefits"
                        />
                        <Input type="text" v-model="formValues.PriorPayment" label="54 Prior Payments" />
                        <Input type="text" v-model="formValues.EstimatedDue" label="55 Estimated Amount Due " />
                    </div>
                </div>

                <!-- Diagnosis Codes Section -->
                <div class="section">
                    <div class="section-label"><span class="inline-block">Diagnosis Codes</span></div>
                    <div class="flex wrap colx4">
                        <Input
                            type="text"
                            v-model="formValues.DiagnosisCodes10.Principal.Code"
                            label="66 Principal Diagnosis Code"
                        />
                        <Input
                            type="text"
                            v-model="formValues.DiagnosisCodes10.Principal.POA"
                            label="66 Principal Diagnosis Code Qualifier"
                        />
                        <template
                            v-if="formValues.DiagnosisCodes10.Other1"
                        >
                            <template
                                v-for="(v, k, i) in formValues.DiagnosisCodes10"
                            >
                                <template
                                    v-if="k != 'Principal'"
                                >
                                    <Input
                                        :key="i"
                                        type="text"
                                        v-model="formValues.DiagnosisCodes10[k].Code"
                                        :label="'67 A Other Diagnosis Code ' + k"
                                    />
                                </template>
                            </template>
                        </template>
                        <Input type="text" v-model="formValues.CodesRequired" label="Codes Required" />
                        <Input
                            type="text"
                            v-model="formValues.DiagnosisCodes10Admitting"
                            label="69 Admitting Diagnosis Code "
                        />
                    </div>
                    <div class="flex wrap colx4">
                        <Input type="text" v-model="formValues.VisitReason1" label="70a Patient’s Reason for Visit " />
                        <Input type="text" v-model="formValues.VisitReason2" label="70b Patient’s Reason for Visit " />
                        <Input type="text" v-model="formValues.VisitReason3" label="70c Patient’s Reason for Visit " />
                        <Input type="text" v-model="formValues.PPSCapDSHDRG" label="71 PPS Code " />
                        <Input
                            type="text"
                            v-model="formValues.DiagnosisCodes10Ecode1"
                            label="72 External Cause of Injury Code "
                        />
                    </div>
                </div>

                <!-- Provider Details Section -->
                <div class="section">
                    <div class="section-label"><span class="inline-block">Provider Details</span></div>
                    <div class="flex wrap colx4">
                        <Input type="text" v-model="formValues.Services[0].RenderingLast" label="76 Attending Provider Last Name" />
                        <Input type="text" v-model="formValues.Services[0].RenderingFirst" label="76 Attending Provider First Name" />
                        <Input type="text" v-model="formValues.Services[0].RenderingProvID.NPI" label="76 Attending ProviderID " />
                        <Input type="text" v-model="formValues.OperatingLast" label="77 Operating Provider Last Name" />
                        <Input type="text" v-model="formValues.OperatingFirst" label="77 Operating Provider First Name" />
                        <Input type="text" v-model="formValues.OperatingProvID" label="77 Operating ProviderID " />
                        <Input type="text" v-model="formValues.ReferringLast" label="78 Referring Provider Last Name" />
                        <Input type="text" v-model="formValues.ReferringFirst" label="78 Referring Provider First Name" />
                        <Input type="text" v-model="formValues.ReferringProvID.NPI" label="78 Referring ProviderID " />
                        <Input type="text" v-model="formValues.RenderingLast" label="79 Rendering Provider Last Name" />
                        <Input type="text" v-model="formValues.RenderingFirst" label="79 Rendering Provider First Name" />
                        <Input type="text" v-model="formValues.RenderingProvID.NPI" label="79 RenderingProviderID " />
                    </div>
                </div>
            </div>
            <button type="submit" class="do-not-print-me">Save</button>
            <button v-if="claimData.saved && claimData.claim_status=='new'" type="button" @click.prevent="handleSubmitClaim" class="do-not-print-me">
                Submit Claim to Clearinghouse API
            </button>
            <button v-else-if="claimData.saved" type="button" @click.prevent="handleSubmitClaim" class="do-not-print-me">
                RE-Submit Claim to Clearinghouse API
            </button>

            <button v-if="0 && claimData.claim_status=='error'" type="button" @click.prevent="delete2api" class="do-not-print-me">
                DELETE Claim AT API ONLY (Keep here)
            </button>

            <button v-if="claimData.claim_status!='new'" type="button" @click.prevent="handle_check_claim_errors" class="do-not-print-me">
                Check Claim Validation / Errors
            </button>

        </form>
    </section>
</template>

<script>
    import { getAllUsStatesAbbreviation } from '@/util/getUsStates';
    import {
        AdmissionSourceOptions,
        AdmissionTypeOptions,
        AssignBenefitsOptions,
        BillTypeOptions,
        ConditionCodesOptions,
        ModifiersCodeList,
        PatientSexOptions,
        PatientStatusOptions,
        PatientRelationshipOptions,
        ROIOptions
    } from '@/util/claimOptions';

    export default {
        name: 'Ub04',
        props: {
            claimData: {
                type: Object,
                required: true
            },
            initialFormValues: {
                type: Object,
                required: true
            },
            diagnosisCodes: {
                type: Array,
                required: true
            },
            serviceFees: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                formValues: {
                    ...this.initialFormValues
                },
                isEditable: true,
                UsStateOptions: getAllUsStatesAbbreviation(),
                PatientSexOptions,
                AdmissionTypeOptions,
                AdmissionSourceOptions,
                PatientStatusOptions,
                PatientRelationshipOptions,
                ModifiersCodeList,
                ConditionCodesOptions,
                BillTypeOptions,
                ROIOptions,
                AssignBenefitsOptions
            };
        },
        methods: {
            handleFormSubmit() {
                this.$emit('saveForm', this.formValues);
            },
            handleSubmitClaim() {
                this.$emit('submitClaim', this.formValues);
            },
            handle_check_claim_errors() {
                this.$emit('check_claim_errors', this.formValues);
            },
            delete2api() {
                this.$emit('delete2api', this.formValues);
            },
        },
        watch: {
            initialFormValues: {
                deep: true,
                handler(newVal) {
                    this.formValues = { ...newVal };
                }
            }
        }
    };
</script>

<style scoped></style>
