<template>
    <div class="container">
        <div class="accordion-header" @click="toggleOpen">
            <div class="accordion-header_icon">
                <slot v-if="open" name="openIcon" />
                <slot v-else name="closedIcon" />
            </div>

            <div class="accordion-header_title">
                <slot name="title" />
            </div>
        </div>

        <div class="accordion-content" :class="contentClass">
            <slot name="content" />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Accordion',
        data() {
            return {
                open: false,
            };
        },
        computed: {
            contentClass() {
                return {
                    'accordion-content_open': this.open,
                    'accordion-content_closed': !this.open,
                };
            },
        },
        methods: {
            toggleOpen() {
                this.open = !this.open;
            },
        },
    };
</script>
